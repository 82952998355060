<template>
  <div class="page-login-desktop">

    <Table
        label="لیست مدیران"
        icon="mdi-format-list-bulleted"
        :table="table">
      <template #header>
        <v-btn color="primary" @click="filters.modal=true">
          جستجو
        </v-btn>
      </template>
      <tr
          v-for="(item,index) in table.items"
          :key="index"
      >
        <td class="text-center">{{ index + 1 }}</td>
        <td class="text-center">{{ item.username }}</td>
        <td class="text-center">{{ item.phoneNumber }}</td>
        <td class="text-center">{{ item.created_at }}</td>

        <td class="text-center">
          <v-chip color="success" text-color="white" v-if="item.status">
            فعال
          </v-chip>
          <v-chip color="error" text-color="white" v-else>
            غیرفعال
          </v-chip>
        </td>
        <td class="text-center">{{ item.lastvisit }}</td>

        <td class="text-center">
         <EditButton
             @click="item.actions.edit"
         />
          <MessageButton
              @click="item.actions.replay"
          />

        </td>
      </tr>
    </Table>

    <v-dialog max-width="500" v-model="filters.modal">
      <v-card>
        <v-card-title class="font-weight-bold text-body-1">
          جستجو مدبران
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col :cols="6">
              <v-text-field
                  v-model="filters.Nameandfamily"
                  outlined
                  label="نام و نام خانوادگی"/>
            </v-col>

            <v-col :cols="6">
              <v-text-field
                  v-model="filters.mobile"
                  outlined
                  label="شماره موبایل"/>
            </v-col>
            <v-col :cols="12">
              <v-select
                  v-model="filters.accountType"
                  outlined
                  :items="adminTypes"
                  item-text="name"
                  item-value="id"
                  label="نوع مدیر"/>
            </v-col>


          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex align-center justify-space-between">
          <v-btn color="warning" @click="resetFilters">
            ریست
          </v-btn>

          <v-btn color="primary" @click="fetchItems">
            جستجو
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

    <Pagination
        v-model="filters.skip"
        :key="filters.skip"
        :totalItems="table.totalCount"
        :perPage="filters.take"
        @change="(page)=>{fetchItems({skip: page})}"
    />

    <NewMessage
        @onSubmit="()=>{
          newMessage.modal=false;
        }"
        :messageData="newMessage"
    />


  </div>
</template>

<script>
import Table from "@/components/Table"
import EditButton from "@/components/EditButton"
import Pagination from "@/components/Pagination"
import {fetchItems} from "@Newfiling/module-admin/src/api";
import moment from "jalali-moment";
import {getDataFromUrl, updateUrlQueries} from "@Newfiling/Services";
import NewMessage from "@/components/NewMessage"
import MessageButton from "@/components/MessageButton"


export default {
  name: 'DesktopLogin',

  components: {
    Table,
    Pagination,
    EditButton,
    NewMessage,
    MessageButton
  },

  data() {
    return {
      adminTypes: [
        {
          id: 3,
          name: 'مدیر کل'
        },
        {
          id: 4,
          name: 'معاون'
        },
        {
          id: 5,
          name: 'منشی'
        },
        {
          id: 6,
          name: 'اپراتور'
        },

        {
          id: 7,
          name: 'ویزیتور'
        },
        {
          id: 8,
          name: 'پاسخگوی مشترکین'
        },


      ],
      filters: {
        modal: false,
        Nameandfamily: null,
        mobile: "",
        skip: 0,
        take: 10,
        accountType: 0,
      },

      table: {
        headers: [
          '#',
          'نام',
          'موبایل',
          'تاریخ ثبت نام',
          'وضعیت',
          'آخرین بازدید',
          'عملیات',
        ],
        items: [],
        totalCount: 0,
        loading: false,
      },

      deleteItem: {
        modal: false,
        loading: false,
        id: null,
      },

      viewMessage: {
        modal: false,
        body: "",
      },

      newMessage: {
        modal: false,
        phoneNumber: null,
      }
    }
  },

  beforeMount() {
    this.$store.commit('Breadcrumb/setBreadcrumb', [
      {
        text: 'پنل مدیریت نیوفایل',
        disabled: false,
        href: '/panel/dashboard',
      },
      {
        text: 'لیست مدیران',
        disabled: true,
        href: '#',
      },
    ])
  },

  mounted() {
    this.$nextTick(() => {
      this.filters = {
        ...this.filters,
        ...getDataFromUrl(this.$route),
      }
      this.fetchItems();
    })

  },
  methods: {
    async fetchItems(filters = {}) {
      this.filters = {
        ...this.filters,
        ...filters
      }
      this.filters.modal = false;
      this.table.loading = true;
      updateUrlQueries(this.$route.path, this.filters);
      try {
        const res = (await fetchItems({
          ...this.filters,
          skip: this.filters.skip * this.filters.take,
        }))?.data || {
          message: [],
          recordcount: 0
        };
        this.table.items = res?.message.map(item => {
          return {
            username: item.nameandfamily,
            phoneNumber: item.mobile,
            created_at: moment(item.dateofRegistration).locale('fa').format('jYYYY-jMM-jDD'),
            status: item.userstatus,
            lastvisit: item.lastvisit,
            actions: {
              edit: () => {
                this.$router.push('/panel/admins/edit/' + item.id)
              },
              replay: () => {
                this.newMessage = {
                  modal: true,
                  phoneNumber: item.mobile.substring(1),
                }
              }
            }
          }
        });
        this.table.totalCount = res?.recordcount;
      } catch (e) {
        console.log(e)
      }
      this.table.loading = false;
    },

    resetFilters() {
      this.filters = {
        ...this.filters,
        modal: false,
        Nameandfamily: null,
        mobile: "",
        skip: 0,
        take: 10,
        accountType: 0,
      }
      this.fetchItems();
    }
  }

}
</script>
